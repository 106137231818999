<template>
	<div class="brand_box clearfix">
<!--		<div v-for="(item, index) in videoList" :key="index" @click="toDetail(item)" class="brand_item">-->
<!--			<div class="brand_item_img">-->
<!--				<img v-if="item.brandImage" class="brand_logo_img" :src="baseUrl + item.brandImage">-->
<!--				<div v-else class="gap"></div>-->
<!--        <div class="video_img">-->
<!--          <img class="product_img" :src="item.imageUrl.split(',')[0]">-->
<!--        </div>-->
<!--			</div>-->
<!--			<div class="brand_div_title" :title="item.name">{{item.pictureName || item.brandEnglishName}}</div>-->
<!--		</div>-->
		<div v-for="(item, index) in videoList" :key="index" @click="toDetail(item)" class="brand_item">
			<div class="type_img_block_content">
				<img :class="['logo-img', 'hide']" :src="baseUrl + item.brandImageUrl + '.png'" style={} >
				<img :src="item.imageUrl">
			</div>
			<div class="type_img_block_title">
				<span>{{item.pictureName || item.brandEnglishName}}</span>
				<img class="active_bg" src="../../assets/other/more.png">
				<img class="default_bg" src="../../assets/other/more2.png">
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'menuList',
  props: {
   videoList: {
		 type: Array
	 }
  },
  data() {
    return {
			baseUrl: 'https://andrnd.com'
    }
  },
	mounted() {},
	methods: {
		toDetail(item) {
			this.$emit('VideoHandleClick', item);
		}
	}
}
</script>

<style scoped>
.gap {
	height: 36px;
}
.brand_box {
	padding-top: 24px;
	padding-right:-10px;
	font-family: Microsoft YaHei;
	width: 100%;
}
.brand_item {
  float: left;
	line-height: 20px;
	border-bottom: 4px solid #939393;
	cursor: pointer;
	padding: 0 24px;
	margin: 0 1% 10px 0;
	 /*width: 424px;*/
	height: 380px;
	background-color: #fff;
	border-bottom: 7px solid #c2c2c2;
	box-sizing: border-box;
	width: 24%;
}
.brand_item:hover {
	border-bottom: 4px solid #7dbd25;
}
.brand_item:hover .brand_operate div p,
.brand_item:hover .brand_operate i {
	color:#7dbd25;
}

.brand_item_img .brand_logo_img {
  width: 90px;
  height: 36px;
  margin: 0 26px 0 auto;
  display: block;
}
.brand_item_img .product_img {
  width: 246px;
  height: 264px;
  margin: 0 auto;
  display: block;
	border: 1px solid #dedede;
}

.brand_operate {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 42px;
	margin-top: 4px;
  text-align: center;
}
.brand_operate div {
 cursor: pointer;
}
.brand_operate i {
	color: #939393;
	font-size: 22px;
	/* width: 24px;
	height: 24px; */
}

.textStyle{
  display: inline-block;
  width: 80px;
  text-align-last: justify;
}

.compatible {
  margin-left: 2px;
}

.notes {
  color: #939393;
  font-size: 12px;
  margin: 0 18px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.notes span {
  margin-right: 5px;
}

.brand_div_title {
	text-overflow: ellipsis;
	overflow: hidden;
  color: #939393;
  font-size: 12px;
  text-align: center;
	height: 24px;
	line-height: 24px;
  margin: 6px auto;
	white-space: nowrap;
	width: 80%;
}

.brand_operate div p {
  color: #939393;
	/* margin-top: 4px; */
  font-size: 12px;
	/* height: 16px; */
	/* line-height: 16px; */
}

.play {
  position: absolute;
  top: 96px;
  left: 96px;
  font-size: 80px;
  color: #d8d7d5;
}

.video_img {
  position: relative;
}

.play_time {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 12px;
  color: #fff;
  background-color: rgba(0,0,0,.5);
  padding: 2px 4px;
}

.type_img_div {
	background-color: #e8e8e8;
	display: flex;
	padding: 10px 10px 0;
	width: 100%;
	box-sizing: border-box;
}

.type_img_block_content {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.type_img_block_content img {
	width: 430px;
	height: 261px;
	border: 1px solid #dedede;
}
.type_img_block_content .logo-img {
	width: 108px;
	height: 40px;
	margin-left: auto;
	border: none;
}
.type_img_block_content .logo-img.hide {
	opacity: 0;
}

.type_img_block_title {
	/* display: flex; */
	/* margin: 10px 5px; */
	height: 64px;
	line-height: 64px;
	position: relative;
	font-family: PingFangSC-Regular, PingFang SC;
	text-align: center;
	font-size: 18px;
}
.brand_item:hover {
	border-bottom: 7px solid var(--main-color);
}
.brand_item:hover .default_bg {
	display: none;
}
.brand_item:hover .active_bg {
	display: block;
}
.type_img_block {
	/* float: left; */
	/* flex: 1; */
	/* padding: 10px 10px 0px 10px; */
	padding: 0 24px;
	margin: 0 2% 10px 0;
	/* width: 424px; */
	height: 306px;
	background-color: #fff;
	border-bottom: 7px solid #c2c2c2;
	box-sizing: border-box;
	width: 23.5%;
}
.type_img_block:nth-last-child(1) {
	margin-right: 0;
}

.type_img_block_title div {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 425px;
	font-size: 18px;
	color: #b1b1b1;
}

.type_img_block_title img{
	position: absolute;
	right: -14px;
	bottom: 6px;
	width: 32px;
	height: 20px;
	display: none;
}
.type_img_block_title .default_bg {
	display: block;
}
</style>
