<template>
  <div id="app">
    <div class="header">
      <div class="logo_img" @click="logoClick">
        <div class="header-logo">
          <img id="header-img" src="../assets/logo_new.png" >
        </div>
        <!-- 搜索关键字-->
        <div class="search_div">
          <div class="search">
            <input type="text" placeholder="搜索关键字" v-model="searchKey" @focus="getSearchList" @input="searchInput" @keyup.enter="handleSearchClick({key:searchKey})">
            <button @click.stop="handleSearchClick({key:searchKey})"><img src="../assets/other/search.png"></button>
          </div>
          <ul v-if="showList">
            <li v-for="(item, index) in searchList" @click="handleSearchClick(item)" :key="index">{{item.key}}</li>
          </ul>
        </div>
      </div>

      <div v-if="seen" class="right_login">
        <el-button v-if="loginShow" type="text" class="header-title m_r_20" @click="login">登录</el-button>
        <div class="user_info_div" v-else>
          <!-- <div class="user_info_single">
            <img src="../assets/other/email.png" class="email_icon">
            <span>E-MAIL</span>
          </div>
          <div class="user_info_single">
            <img src="../assets/other/phone.png">
            <span>010-85715681</span>
          </div> -->
          <div v-if="isLogin()" @click="toPerson" class="user_info_single">
            <img v-if="user.headImage" :src="user.headImage">
            <img v-else src="../assets/other/user.png">
            <span>{{user.name || user.code}}</span>
          </div>
          <div v-else @click="toLogin" class="user_info_single user_info_single_login" style="color: #2d2d34">
            <img src="../assets/other/user.png">
            <span>登陆</span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="menu-div">
        <div @click="activeIndex = 1 && handleSelect('1')" :class="activeIndex == 1 ? 'menu-select': ''">首页</div>
        <div @click="activeIndex = 2 && handleSelect('2')" :class="activeIndex == 2 ? 'menu-select': ''">产品目录</div>
        <div @click="activeIndex = 3 && handleSelect('3')" :class="activeIndex == 3 ? 'menu-select': ''">2D/3D图纸</div>
        <!-- <div @click="activeIndex = 4 && handleSelect('4')" :class="activeIndex == 4 ? 'menu-select': ''">新品发布</div> -->
        <div @click="activeIndex = 5 && handleSelect('5')" :class="activeIndex == 5 ? 'menu-select': ''">产品视频</div>
        <div @click="activeIndex = 9 && handleSelect('9')" :class="activeIndex == 9 ? 'menu-select': ''">产品图片</div>
        <div @click="activeIndex = 6 && handleSelect('6')" :class="activeIndex == 6 ? 'menu-select': ''">产品研讨</div>
        <div v-if="!isLogin()" @click="activeIndex = 7 && handleSelect('7')" :class="activeIndex == 7 ? 'menu-select': ''">入驻平台</div>
        <div v-if="isLogin()" @click="activeIndex = 8 && handleSelect('8')" :class="activeIndex == 8 ? 'menu-select': ''">个人中心</div>
      </div>
      <!-- web端菜单导航 -->
      <el-menu v-if="seen && false" :default-active="activeIndex" background-color="#ffffff" class="el-menu-demo menu_web" menu-trigger="click" text-color="#e0e7e8" active-text-color="#e0e7e8" mode="horizontal" @select="handleSelect">
        <el-menu-item index="1" class="header-title">首页</el-menu-item>
        <el-menu-item index="2" class="header-title">产品目录</el-menu-item>
        <el-menu-item index="3" class="header-title">2D/3D图纸</el-menu-item>
        <!-- <el-menu-item index="4" class="header-title">新品发布</el-menu-item> -->
        <el-menu-item index="5" class="header-title">产品视频</el-menu-item>
        <el-menu-item index="6" class="header-title">产品研讨</el-menu-item>
        <el-menu-item v-if="!isLogin()" index="7" class="header-title">入驻平台</el-menu-item>
        <el-menu-item v-if="isLogin()" index="8" class="header-title">个人中心</el-menu-item>
      </el-menu>
      <!-- 手机端菜单导航 v-bind:style="{backgroundColor:'#409EFF'}" -->
      <el-menu v-if="!seen" :default-active="activeIndex" class="el-menu-demo menu_phone" menu-trigger="click" background-color="#021528" text-color="#e0e7e8" active-text-color="#e0e7e8" mode="horizontal" @select="handleSelectMobile">
        <el-submenu index="1" class="header-title mobile_menu">
          <template slot="title">{{currentMenu}}</template>
          <el-menu-item index="1" class="header-title">首页</el-menu-item>
          <el-menu-item index="2" class="header-title">产品目录</el-menu-item>
          <el-menu-item index="3" class="header-title">2D/3D图纸</el-menu-item>
          <el-menu-item index="4" class="header-title">新品发布</el-menu-item>
          <el-menu-item index="5" class="header-title">产品视频</el-menu-item>
          <el-menu-item index="6" class="header-title">产品研讨</el-menu-item>
          <el-menu-item index="7" class="header-title">入驻平台</el-menu-item>
          <el-menu-item  v-if="isLogin()" index="8" class="header-title">个人中心</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <div class="container clear_both">
      <router-view></router-view>
    </div>
    <div class="footer_div">
      <!-- <div class="footer_div_top"></div> -->
      <div class="footer_div_content">
        <div class="function">
          <div>
            <p class="title">常用功能</p>
            <p style="cursor: pointer;" @click="toEntry">供应商入口</p>
            <p style="cursor: pointer;" @click="toLogin2">工程师入口</p>
          </div>

          <div>
            <p class="title">关于我们</p>
            <p style="cursor: pointer;" @click="toAboutUs">关于我们</p>
            <p style="cursor: pointer;" @click="toAboutUs">联系我们</p>
          </div>

          <div>
            <p class="title">工程师联系方式</p>
            <p style="margin: 0;">185-0138-8091 周女士</p>

            <p>ENG@andrnd.com</p>
          </div>

          <div>
            <p class="title">供应商联系方式</p>
            <p style="margin: 0;">185-1531-7316 朱女士</p>

            <p>SRM@andrnd.com</p>
          </div>



          <div>
            <p class="title">综合办公室联系方式</p>
            <p style="margin: 0;">178-0108-3963 陈女士</p>

            <p>admin@andrnd.com</p>
          </div>

        </div>


        <!-- <div class="contact">
          <div>
            <p class="title"><span>工程师联系方式</span></p>
            <p style="margin: 0;">186-1111-2222 张先生</p>
            <p style="margin: 0;">186-1111-2222 张先生</p>
            <p>afgc@bueradesign.com</p>
          </div>
          <div>
            <p class="title"><img src="../assets/other/footer_email.png" class="footer_email"><span>电子邮件</span></p>
            <p>afgc@bueradesign.com</p>
          </div>
        </div> -->
        <div class="line"></div>
        <div class="code">
          <div>
            <img src="../assets/other/qrcode_wechat.jpg" class="footer_qrcode">
            <p>微信公众号</p>
          </div>
          <div>
            <img src="../assets/other/gh_wechat.jpg" class="footer_qrcode">
            <p>微信小程序</p>
          </div>
        </div>
      </div>
      <div class="footer_div_bottom">
        <span> Copyright 2023 www.andrnd.com All Rights Reserved</span><span></span><span>津ICP备18002450号</span>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="showLogin"
      width="40%">
      <login-info :visible.sync="showLogin" @loginCb="loginCb" @signupClick="signupClick"></login-info>
    </el-dialog>
     <el-dialog
      title=""
      :visible.sync="showSignup"
      width="40%">
      <signup :visible.sync="showSignup" @showSignup="loginCb"></signup>
    </el-dialog>
<!--        <div id="back_to_top" ref="btn" @click="backTop" style="display: none;">-->
<!--            <p class="top_p">TOP</p>-->
<!--            <img src="../assets/other/launch.png" class="top_img" />-->
<!--        </div>-->
  </div>
</template>

<script>
import LoginInfo from './public/loginInfo.vue'
import Signup from './Signup.vue'
import Bus from '../bus'
import { SavePdfKey, GetPdfSearchList } from '../api/pdf/api'
  export default {
    name: "Layout",
    data() {
      return {
        showList: false,
        clicked: false,
        searchList: [
          // {
          //   key: 'aaa',
          // },
          // {
          //   key: 'bbb',
          // },
          // {
          //   key: 'ccc',
          // }
        ],
        showSignup: false,
        showLogin: false,
        activeIndex: '1',
        company:{},
        scroll: '',
        timer: '',
        seen: true,
        currentMenu:'首页',
        searchKey:'',
        loginShow:false,
        user: {},
      };
    },
    methods: {
      handleSearchClick(item) {
        this.showList = false;
        this.clicked = true;
        // window.updateSearch && window.updateSearch(item.key)
        // Bus.$emit('updateSearch', item.key);
        this.searchKey = item.key
        this.$router.push({
          path: '/Pdf/PdfSearch',
          query: {
            searching: 1,
            searchKey: this.searchKey
          }
        })
      },
      toPerson() {
        this.$router.push('/Person');
      },
      toEntry() {
        window.open('http://b.andrnd.com/ids/#/login')
      },
      signupClick() {
        this.showSignup = true;
      },
      getUserInfo() {
        this.$nextTick(() => {
          let userObj = window.localStorage && window.localStorage.getItem('user');
          this.user = {...JSON.parse(userObj)};
        })
      },
      toAboutUs() {
        window.open(window.location.origin + '#/AboutUs')
        // this.$router.push('/AboutUs');
      },
      toRegister() {
        // this.$router.push('/Signup')
      },
      toLogin() {
        // this.$router.push('/Login')
        this.showLogin = true;
      },
      toLogin2() {
        if(!this.isLogin()) {
          this.toLogin();
        } else {
          this.toPerson();
        }
      },
      handleClose(done) {
        this.$confirm('确认关闭？').then(_ => {
          done();
        }).catch(_ => {});
      },
      loginCb(data) {
        // window.location.reload();
        this.user = {...data}
      },
      isLogin() {
        return !!(window.localStorage && window.localStorage.getItem('token'))
      },
      handleSelect(key) {
        switch (key){
          case '1':
            this.$router.push({name: "Index"});
            break;
          case '2':
            this.$router.push({name: "Pdf"});
            break;
          case '3':
            this.$router.push({name: "Blueprint2D3D"});
            break;
          case '4':
            this.$router.push({name: "NewProductRelease"});
            break;
          case '5':
            this.$router.push({name: "ProductVideo"});
            break;
          case '6':
            this.$router.push({name: "ProductDiscussion"});
            this.$router.go(0);
            break;
          case '7':
            this.$router.push({name: "EntryPlatform"});
            break;
          case '8':
            this.$router.push({name: "Person"});
            break;
          case '9':
            this.$router.push({name: "ProductPic"});
            break;
        }
      },
      handleSelectMobile(key) {
        switch (key){
          case '1':
            this.$router.push({name: "Index"});
            this.currentMenu='首页';
            break;
          case '2':
            this.$router.push({name: "Pdf"});
            this.currentMenu='PDF目录';
            break;
          case '3':
            this.$router.push({name: "Blueprint2D3D"});
            this.currentMenu='2D/3D图纸';
            break;
          case '4':
            this.$router.push({name: "NewProductRelease"});
            this.currentMenu='新品发布';
            break;
          case '5':
            this.$router.push({name: "ProductVideo"});
            this.currentMenu='产品视频';
            break;
          case '6':
            this.$router.push({name: "ProductDiscussion"});
            this.currentMenu='产品研讨';
            break;
          case '7':
            this.$router.push({name: "EntryPlatform"});
            this.currentMenu='入驻平台';
            break;
          case '8':
            this.$router.push({name: "Person"});
            this.currentMenu='个人中心';
            break;
          case '9':
            this.$router.push({name: "ProductPic"});
            this.currentMenu='产品图片';
            break;
        }
      },
      handleScroll(){
        this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
        if( this.scroll >600 ){  //判断滚动后高度超过400px,就显示
          this.$refs.btn && (this.$refs.btn.style.display = 'block');
        }else{
          this.$refs.btn && (this.$refs.btn.style.display = 'none');
        }
      },
      backTop(){ //当点击标签的时候,使用animate在200毫秒的时间内,滚到顶部
        this.timer = setInterval(() => {
          let osTop = document.documentElement.scrollTop || document.body.scrollTop;
          let ispeed = Math.floor(-osTop / 5)
          document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
          if (osTop === 0) {
            clearInterval(this.timer)
          }
        }, 30)
      },
      initMenu() {
        if(this.$route.name==='Index'){
          this.activeIndex='1';
          this.currentMenu='首页';
        }else if(this.$route.name==='Pdf' || this.$route.name==='PdfSearch' || this.$route.name === 'PdfDetail'){
          this.activeIndex='2';
          this.currentMenu='PDF目录';
        }else if(this.$route.name==='Blueprint2D3D' || this.$route.name === 'CanvasDetail'){
          this.activeIndex='3';
          this.currentMenu='2D/3D图纸';
        }else if(this.$route.name==='NewProductRelease'){
          this.activeIndex='4';
          this.currentMenu='新品发布';
        }else if(this.$route.name==='ProductVideo'){
          this.activeIndex='5';
          this.currentMenu='产品视频';
        }else if(this.$route.name==='ProductDiscussion'){
          this.activeIndex='6';
          this.currentMenu='产品研讨';
        }else if(this.$route.name==='EntryPlatform'){
          this.activeIndex='7';
          this.currentMenu='入驻平台';
        }else if(this.$route.name==='Person'){
          this.activeIndex='8';
          this.currentMenu='个人中心';
        }else if(this.$route.name==='ProductPic'){
          this.activeIndex='9';
          this.currentMenu='产品视频';
        }
      },
      menuSeen(){
        // 屏幕尺寸
        let screenHeight=document.body.clientWidth;
        if(screenHeight>415){
            this.seen=true;
        }else{
            this.seen=false;
        }
      },
      logoClick(){
        this.$router.push({name: "Index"});
        this.activeIndex='1';
        this.currentMenu='首页';
      },
      searchInput() {
        this.clicked = false;
      },
      getSearchList() {
        this.searchKey && GetPdfSearchList({
          pdfKeys: this.searchKey
        }).then(res => {
          if(res && res.length) {
            this.searchList = [...res];
            if(this.clicked) {
              this.showList = false
            } else {
              this.showList = true
            }
          } else {
            this.searchList = []
            this.showList = false
          }
        })
      },
      search(){
        window.localStorage &&  window.localStorage.setItem('searchKey', this.searchKey);
        this.activeIndex = '2';
        this.currentMenu = 'PDF目录';
        SavePdfKey({
          pdfKeys: this.searchKey
        }).then(res => {})
        if (this.$route.path === '/Pdf' || this.$route.path === '/Pdf/PdfSearch') {

        } else {
          this.$router.push({
            path: '/Pdf/PdfSearch',
            query: {
              searching: 1,
              searchKey: this.searchKey
            }
          })
        }
      },
      handleLoginClose() {
        Bus.$emit('showLogin', false);
      }
    },
    watch: {
      $route(){
        this.initMenu()
      },
      searchKey(val) {
        val && this.getSearchList();
      }
    },
    mounted() {
      var orignalSetItem = localStorage.setItem;
      localStorage.setItem = function(key, newValue){
        var setItemEvent = new Event('setItemEvent');
        setItemEvent.newValue = newValue;
        setItemEvent.key = key;
        window.dispatchEvent(setItemEvent);
        orignalSetItem.apply(this, arguments);
      }
      this.menuSeen()
      window.addEventListener('scroll', this.handleScroll)
      // 判断当前路由确定菜单选项给activeIndex赋值
      this.initMenu()
      this.getUserInfo();
      // window.addEventListener('setItemEvent', (e) => {
      //   if(e.key === 'user') {
      //     this.getUserInfo();
      //   }
      // })
      window.selfEventSm = {};
      let _this = this;
      window.selfEventSm.clearSearchKey = () => {
        _this.searchKey = '';
      }
      Bus.$on('showLogin', val => {
        this.showLogin = val;
      });
      Bus.$on('showSignup', val => {
        this.showSignup = val;
      });
      Bus.$on('setUser', obj => {
        this.user = {...obj};
        this.$forceUpdate();
      });
    },
    components: {
      LoginInfo,
      Signup
    }
  }
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
.user_info_div {
  color: #fff;
  font-size: 22px;
  display: flex;
  font-family: Microsoft YaHei;
}
.user_info_single {
  margin-right: 20px;
  display: flex;
  align-items: center;
}
.user_info_single:nth-last-child(1) {
  cursor: pointer;
}

.user_info_single_login span{
  cursor: pointer;
}

.user_info_single img{
  width: 22px;
  height: 22px;
  margin-right: 5px;
}

.email_icon {
  width: 33px !important;
  height: 22px;
  margin-right: 5px;
}

.menu-div {
  display: flex;
  align-items: center;
  height: 40px;
  color: #021528;
  font-size: 20px;
  font-family: Microsoft YaHei;
  position: relative;
  margin-left: 280px;
  /*顶部固定*/
  position: fixed;
  top: 50px;
  left: 0;
  background-color: #ffffff;
  z-index: 998;
  width: 100%;
  padding-left: 300px;
  margin-left: 0;
}
.menu-div div {
  margin-right: 30px;
  cursor: pointer;
  height: 30px;
  line-height: 30px;
}
.menu-select {
  border-bottom: 4px solid #4e6ef2;
}

.container {
  margin-top: 90px;
}
</style>
